import React, { Component } from "react";
import { Col, Container, Table } from "reactstrap";
import Alerts from "../../../../components/Alerts";
import moment from "moment";

export class Historial extends Component {

    constructor(props) {
        super(...arguments);
        this.alert = React.createRef();
        this.state = {
            value: ""
        };
    }

    render() {
        return (
            <React.Fragment>
                <Alerts ref={this.alert} />
                <Container className="text-center">
                    <Col className={"table-responsive"}>
                        <React.Fragment>
                            <Table className="table table-borderless table-hover">
                                <thead>
                                    <tr className="text-secondary">
                                        <th className="bg-white" scope="col">Item</th>
                                        <th className="bg-white" scope="col">Fecha</th>
                                        <th className="bg-white" scope="col">Estado</th>
                                        <th className="bg-white" scope="col">Observación</th>
                                        <th className="bg-white" scope="col">Carta</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.historial.length === 0 ?
                                        <React.Fragment>
                                            <tr>
                                                <td colSpan={5}>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            {this.props.historial.map((item, index) => (
                                                <tr key={item.cdCobertura}>
                                                    <td className="text-center">
                                                        <span>{index + 1}</span>
                                                    </td>
                                                    <td className="text-center">
                                                        <span>
                                                            {item.fcSeguimiento}
                                                        </span>
                                                    </td>
                                                    <td className="text-left">
                                                        <span>{item.dscEstado}</span>
                                                    </td>
                                                    <td className="text-left">
                                                        <span>{item.observacion}</span>
                                                    </td>
                                                    <td className="text-left">
                                                        <span>{item.carta}</span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </React.Fragment>
                                    }
                                </tbody>
                            </Table>
                        </React.Fragment>
                    </Col>
                </Container>
            </React.Fragment>
        )
    }
}